var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ce-memberarea--content"},[_c('div',{staticClass:"ce-memberarea-image--wrapper"},[_c('TwicImg',{staticClass:"ce-memberarea--image",attrs:{"src":_vm.selectedImageUrl,"alt":"Image présentant les fonctionnalités clés de notre espace membre","ratio":_vm.isMobile ? 'none' : 'none',"mode":_vm.isMobile ? 'contain' : 'cover',"focus":"auto"}})],1),_c('Slider',{staticClass:"ce-memberarea--claims",attrs:{"disabled":!_vm.isMobile,"options":{ perView: 1.1 },"disable-arrows":"","disable-dots":false},on:{"slide":_vm.selectImageId}},[_c('div',{class:[
        'ce-memberarea--claim',
        { 'is-active': _vm.isImageActive(_vm.SHIPPING_ID) } ],on:{"click":function($event){return _vm.selectImageId(_vm.SHIPPING_ID)},"mouseenter":function($event){return _vm.selectImageId(_vm.SHIPPING_ID)}}},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"strong"}},[_vm._v("\n        Des livraisons selon ses besoins\n      ")]),_c('Typography',{attrs:{"tag":"p"}},[_vm._v("\n        Recevez ses croquettes à une fréquence personnalisée selon ses\n        besoins. Ça ne convient pas ? Modifiez vos préférences en un clic\n        depuis votre Espace Membre !\n      ")])],1),_c('div',{class:[
        'ce-memberarea--claim',
        { 'is-active': _vm.isImageActive(_vm.RECIPE_ID) } ],on:{"click":function($event){return _vm.selectImageId(_vm.RECIPE_ID)},"mouseenter":function($event){return _vm.selectImageId(_vm.RECIPE_ID)}}},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"strong"}},[_vm._v("\n        Son évolution\n      ")]),_c('Typography',{attrs:{"tag":"p"}},[_vm._v("\n        Tout au long de sa vie, on adapte automatiquement sa recette et ses\n        quantités !\n      ")])],1),_c('div',{class:[
        'ce-memberarea--claim',
        { 'is-active': _vm.isImageActive(_vm.GOODIES_ID) } ],on:{"click":function($event){return _vm.selectImageId(_vm.GOODIES_ID)},"mouseenter":function($event){return _vm.selectImageId(_vm.GOODIES_ID)}}},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"strong"}},[_vm._v("\n        Des surprises à gogo\n      ")]),_c('Typography',{attrs:{"tag":"p"}},[_vm._v("\n        Des jouets, peluches, accessoires… en plus des croquettes pour faire\n        plaisir à votre animal !\n      ")])],1),_c('div',{class:[
        'ce-memberarea--claim',
        { 'is-active': _vm.isImageActive(_vm.PRODUCTS_ID) } ],on:{"click":function($event){return _vm.selectImageId(_vm.PRODUCTS_ID)},"mouseenter":function($event){return _vm.selectImageId(_vm.PRODUCTS_ID)}}},[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.h4,"tag":"strong"}},[_vm._v("\n        Des produits adaptés à ses besoins\n      ")]),_c('Typography',{attrs:{"tag":"p"}},[_vm._v("\n        Composez votre box et ajoutez tous les autres produits que vous voulez\n        pour gâter votre boule de poils (friandises, terrines, compléments\n        alimentaires…)\n      ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }