<template>
  <div class="ce-faq--wrapper">
    <Animation trigger-on-scroll v-bind="BLOCK_IN">
      <div class="ce-faq--aside">
        <img
          class="ce-faq--image"
          src="@front/common/assets/coeur_pattes.svg"
          alt="Cœur avec des pattes"
          height="120"
          width="120"
        />

        <Typography
          :variant="TYPOGRAPHY_TYPES.h2"
          class="ce-faq--title"
          tag="h2"
        >
          Nos réponses à vos questions
        </Typography>

        <Typography class="ce-faq-text--description">
          Tout ce que vous devez savoir sur Japhy !
        </Typography>

        <img
          class="ce-faq-arrow--image"
          src="@front/common/assets/arrow_down.svg"
          alt="Flèche vers le bas"
        />
      </div>
    </Animation>

    <div class="ce-faq-accordeon--wrapper">
      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Quels sont les délais de livraison ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Nos commandes sont expédiées en 24h et livrées en 3 à 4 jours
              ouvrés.
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Comment fonctionne l'abonnement Japhy ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Dans un premier temps, vous recevez une offre découverte qui
              correspond à environ 10 ou 15 jours d'alimentation pour votre
              animal, pour tester d'abord sur une courte durée. Ensuite, si les
              croquettes lui conviennent, vous êtes livré toutes les 4,5 ou 6
              semaines. Vous pouvez modifiez votre contenance et vos dates de
              livraison, simplement depuis votre Espace Membre. L'abonnement est
              sans engagement et résiliable à tout moment (notamment directement
              après l'offre découverte).
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Où sont fabriqués les produits Japhy ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Nous avons à coeur de favoriser les circuits courts et les
              entreprises françaises. Ainsi, toutes nos croquettes sont
              fabriquées dans une usine partenaire dans le Pays de la Loire. Nos
              émincés et nos terrines sont produits à Boulogne-sur-Mer, et notre
              litière est conçue en Eure et Loir. Le reste de nos produits sont
              fabriqués en Europe.
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              En quoi les recettes sont-elles naturelles ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Nos produits sont fabriqués à partir d'ingrédients naturels et
              subissent le moins de transformation possible. Nos recettes sont
              formulées sans colorants, sans émulsifiants, sans sucre ajouté et
              sans OGM* (*conformément à la législation en vigueur). Nous
              mettons un point d'honneur dans la création de nos produits à la
              naturalité !
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Comment le menu personnalisé de mon animal est-il créé ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Chez Japhy, nous avons plusieurs dizaines de recettes de
              croquettes élaborées avec soin. Chaque recette est pensée pour un
              type de chien ou de chat précis. Selon les critères renseignés sur
              le profil de votre animal, nous vous recommandons ensuite une à
              deux recettes qui correspond(ent) parfaitement au profil de votre
              animal. Grâce à notre algorithme, nous calculons ensuite avec
              précision le grammage nécessaire selon ses propres besoins
              journaliers. Ensuite, vous êtes livrés à la fréquence parfaite,
              pour ne jamais manquer de croquettes ni avoir de sur-stockage.
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Les croquettes Japhy sont-elles adaptées aux chiots et aux chatons
              ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Nous avons deux recettes adaptées aux chatons et trois recettes
              adaptées aux chiots. Notre but étant d’accompagner votre animal
              dès son plus jeune âge, cela nous semble essentiel de proposer des
              recettes pour les animaux chiots et chatons.
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Les croquettes Japhy sont-elles adaptées aux chiens et chats
              seniors ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Nous proposons effectivement des recettes adaptées aux animaux
              seniors ! Afin de les accompagner dans toutes leurs étapes de vie,
              nous avons pensé des recettes de croquettes adaptées à chaque
              étape de vie.
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Est-ce que je peux modifier mon menu s’il ne convient pas à mon
              animal ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Chez Japhy, nous préconisons une recette bien précise, ainsi qu’un
              grammage personnalisé pour votre animal, mais nous vous laissons
              la main complète sur son menu. Car vous le connaissez mieux que
              personne, vous pouvez ajuster sa dose de croquettes, changer la
              saveur de sa recette et choisir votre fréquence de livraison
              depuis votre Espace Membre. Vous pouvez aussi choisir d’ajouter
              des produits complémentaires, comme des terrines ou des
              compléments alimentaires.
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Qu’est-ce que la transition alimentaire ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              La transition alimentaire est une étape essentielle lorsque l’on
              change les croquettes d’un animal. Cela consiste à introduire
              progressivement la nouvelle alimentation en la mélangeant à celle
              donnée habituellement. Cela peut prendre quelques jours, jusqu’à
              une semaine pour les animaux les plus sensibles.
            </Typography>
          </template>
        </Accordeon>
      </Animation>

      <Animation trigger-on-scroll>
        <Accordeon class="ce-faq--accordeon">
          <template #trigger>
            <Typography :variant="TYPOGRAPHY_TYPES.bodyBold">
              Comment déterminez-vous les rations de mon animal ?
            </Typography>
          </template>

          <template #content>
            <Typography>
              Nous prenons en compte tous les critères de votre animal,
              notamment son poids, son âge, sa corpulence et son activité
              physique. Ensuite, notre algorithme, élaboré par des
              professionnels de la nutrition animal, calcule ses besoins
              nutritionnels journaliers et indique le grammage précis (et
              unique) dont chaque chien ou chat a besoin.
            </Typography>
          </template>
        </Accordeon>
      </Animation>
    </div>
  </div>
</template>

<script>
import { Accordeon, Animation, Typography, TYPOGRAPHY_TYPES } from '@front/ui'
import { BLOCK_IN } from '@front/ui/Animation/animations'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: { Typography, Accordeon, Animation },
  setup() {
    return { TYPOGRAPHY_TYPES, BLOCK_IN }
  },
})
</script>

<style lang="scss" scoped>
.ce-faq--wrapper {
  display: flex;
  padding: $ds-spacing-mobile-large 0px;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    justify-content: space-between;
    flex-direction: row;
  }
}

.ce-faq--image {
  width: 120px;
}

.ce-faq--title {
  margin-top: $ds-spacing-mobile-extra-small;
  margin-bottom: $ds-spacing-mobile-small;
  max-width: 320px;
}

.ce-faq--aside {
  display: flex;
  flex-direction: column;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 370px;
  }
}

.ce-faq-accordeon--wrapper {
  display: flex;
  flex-direction: column;
  gap: $ds-spacing-mobile-small;
  width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 50%;
  }
}

.ce-faq-text--description {
  display: none;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: block;
  }
}

.ce-faq--accordeon {
  background: $ds-color-black-background;
  padding: $ds-spacing-mobile-small;
  border-radius: 8px;
}

.ce-faq-arrow--image {
  display: none;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: block;
    margin-left: auto;
    margin-top: $ds-spacing-mobile-medium;
    width: 68px;
  }
}
</style>
