// eslint-disable-next-line import/prefer-default-export
export const imageUrlByBreed = {
  'cat/turkish_angora':
    'https://japhy-assets.twic.pics/download?id=11nlBqWxdmCyi4LyJf73WbjArxgkrzUTg',
  'dog/MGR/beagle':
    'https://japhy-assets.twic.pics/download?id=1dHMOuXND8q1CZF1dLbsdreWtqhQ5CFln',
  'cat/bengale':
    'https://japhy-assets.twic.pics/download?id=12-uG_H5mCkXzWLZo_OA0FTfj7RZypZio',
  'dog/TGR/german_shepherd':
    'https://japhy-assets.twic.pics/download?id=1dEUkr0F0ROcRWcZbPDTCvARgEpwzyPoP',
  'dog/MGR/australian_shepherd':
    'https://japhy-assets.twic.pics/download?id=13doxJGD-qx9mx9GHyEa1XOp_Y1cDpKr1',
  'dog/MGR/belgian_shepherd_malinois':
    'https://japhy-assets.twic.pics/download?id=134T3-tq-dTBHi_gPp7Vaf9LiQV3so6yB',
  'dog/PR/bichon_frize':
    'https://japhy-assets.twic.pics/download?id=12ZnYbnW2eZssYWb4tndohA92ybtI3J2T',
  'dog/PR/maltese':
    'https://japhy-assets.twic.pics/download?id=12UTjDCt0mBGZR-ZKb2r9omnBAMnVEEG8',
  'dog/PR/dachshund':
    'https://japhy-assets.twic.pics/download?id=12jCgOXqQue12Kjtg5tcUIwDBIxS6MqXO',
  'dog/MGR/border_collie':
    'https://japhy-assets.twic.pics/download?id=13CKP39JMD8pC-2ZR5ycDNyKaf4H7LgTc',
  'dog/MGR/english_bulldog':
    'https://japhy-assets.twic.pics/download?id=13Gvd5Uz5cI-pSzOGZsM736pCCMKQsipA',
  'dog/MGR/french_bulldog':
    'https://japhy-assets.twic.pics/download?id=1dQtceImkDAwZyJniXbiW4lNcWMMLLpyx',
  'dog/TGR/bernese_mountain_dog':
    'https://japhy-assets.twic.pics/download?id=13_mS6azDiFvNeammU5ZhtM1t6mjy8D2_',
  'dog/PR/king_charles':
    'https://japhy-assets.twic.pics/download?id=12OsTm5ytWN6jhwgzmvJbQDkp-12V0460',
  'dog/PR/king_charles_spaniel':
    'https://japhy-assets.twic.pics/download?id=12OsTm5ytWN6jhwgzmvJbQDkp-12V0460',
  'cat/carthusian':
    'https://japhy-assets.twic.pics/download?id=1d5z1cD5vS0sOy-mRt4rgVGtwqeVxY_c4',
  'cat/common':
    'https://japhy-assets.twic.pics/download?id=121WXMdGzqBWhYkShadUiCGtyMUlxIAmp',
  'dog/PR/chihuahua':
    'https://japhy-assets.twic.pics/download?id=12cFh7J4zftoOuG2t61umjOZpPls85ygc',
  'dog/MGR/english_cocker_spaniel':
    'https://japhy-assets.twic.pics/download?id=1j1-c6LtQweRIUqF7MXrsVWo3SGtiNYXb',
  'dog/MGR/brittany_spaniel':
    'https://japhy-assets.twic.pics/download?id=13PXbaR_LmdkQ9MIO4wPVjT3oLy2rtQ8G',
  'dog/MGR/eurasier':
    'https://japhy-assets.twic.pics/download?id=1dTEXnnrME4AKuty2nqlucfHyQpLawIZd',
  'dog/TGR/golden_retriever':
    'https://japhy-assets.twic.pics/download?id=1d8bPrpek00hgNdo5X_AiYJ0HGZNS_MRX',
  'dog/MGR/labrador_retriever':
    'https://japhy-assets.twic.pics/download?id=16zGC-2xlrdbyeAf0AS3obA4bcCEvApZA',
  'dog/MGR/siberian_husky':
    'https://japhy-assets.twic.pics/download?id=13SdfW75M0YJ38CntHaVI0gGdOcFOwqk2',
  'cat/maine_coon':
    'https://japhy-assets.twic.pics/download?id=11k6B6DiqoL40y1bYcYZ0lt7KzlrNGCPq',
  'dog/PR/shiba_inu':
    'https://japhy-assets.twic.pics/download?id=1roIfbGuD4Sf7E0rBLFhaiijvPAhe5nqQ',
  'dog/PR/shih_tzu':
    'https://japhy-assets.twic.pics/download?id=12oxqYAZvfbXATrcUa-r4YsnSkoWltjLC',
  'cat/siamese':
    'https://japhy-assets.twic.pics/download?id=12Deul5N4k9MIImD281qhnHki9FZu9UIr',
  'cat/sphynx':
    'https://japhy-assets.twic.pics/download?id=120L6EOyR84X5vPRuOZ-upcUW4lEsWCWc',
  'dog/PR/german_spitz':
    'https://japhy-assets.twic.pics/download?id=17EXDLrJEcic6_0j-OkP1g6AqKNDt0stQ',
  'dog/MGR/staffordshire_bull_terrier':
    'https://japhy-assets.twic.pics/download?id=13Rc_vCcP1cp_Ko-Hl1tSZ4uJLt41AG1o',
  'dog/PR/yorkshire_terrier':
    'https://japhy-assets.twic.pics/download?id=17ASpAhmP47E07x71HCDFe24K0XMG5s_W',
  // 'dog/TGR/american_staffordshire_terrier':
  //   'https://japhy-assets.twic.pics/download?id=16jcu1A-YxPN4Zx16EL2nLNqZH9LgNyS0',
  'cat/alley_cat':
    'https://japhy-assets.twic.pics/download?id=121WXMdGzqBWhYkShadUiCGtyMUlxIAmp',
}
