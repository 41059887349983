import { assign, send } from 'xstate'

import { subMachines } from './constants'
import { createSubMachines } from './utils'

export const findField = (fields, name) => {
  return fields.find(({ field }) => field.name === name)
}

export default {
  'FIELD.CHANGE': {
    actions: [
      assign({ dirty: true }),
      (context, event) => {
        const field = findField(context.fields, event.name)

        if (!field) {
          return
        }

        field.ref.send('CHANGE', { data: event.data })
      },
    ],
  },
  'FIELD.COMMIT': {
    actions: assign({
      values: (context, event) => ({
        ...context.values,
        [event.field.name]: event.field.value,
      }),
      touched: (context, event) => ({
        ...context.touched,
        [event.field.name]: event.field.touched,
      }),
      dirtyFields: (context, event) => ({
        ...context.dirtyFields,
        [event.field.name]: event.field.dirty,
      }),
    }),
  },
  'FIELD.BLUR': {
    actions: (context, event) => {
      const field = findField(context.fields, event.name)

      if (!field) {
        return
      }

      field.ref.send('BLUR')
    },
  },
  UPDATE_SCHEMA: {
    actions: assign({
      schema: (context, { newSchema }) => newSchema,
      i18n: (context) => context.i18n,
      touched: (context) => context.touched,
      errors: (context) => context.errors,
      dirty: (context) => context.dirty,
      isSubmiting: (context) => context.isSubmiting,
      fields: ({ ...context }, { newSchema }) =>
        createSubMachines(newSchema, context, subMachines),
      values: ({ values = {} }) => values,
    }),
  },
  SUBMIT_FORM: {
    actions: [
      assign({
        isSubmiting: true,
      }),
      send('VALIDATE_FIELDS'),
    ],
  },
  VALIDATE_FIELDS: {
    target: 'validating',
  },
  SET_ERRORS: {
    actions: assign({
      errors: (_, { errors }) => errors,
      touched: (context, { errors }) => {
        return {
          ...context.touched,
          ...Object.keys(errors).reduce((acc, item) => {
            return {
              ...acc,
              [item]: true,
            }
          }, {}),
        }
      },
    }),
  },
}
